import React, { useState, useEffect } from "react";

const PreloadedIframe = ({ src, title, height }) => {
  const [isLoading, setIsLoading] = useState(true);

  const handleLoad = () => {
    setIsLoading(false);
  };

  return (
    <div style={{ height }} className="relative w-full">
      {isLoading && (
        <div className="absolute inset-0 flex items-center justify-center bg-gray-800 bg-opacity-75 z-50">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-blue-400" />
        </div>
      )}
      <iframe
        src={src}
        title={title}
        className="w-full h-full"
        onLoad={handleLoad}
        frameBorder="0"
      />
    </div>
  );
};

const FilterSection = ({ children, title, isLoading }) => (
  <div className="mb-6">
    <h3 className="text-white text-lg font-medium mb-3">{title}</h3>
    <div className="space-y-3">
      {isLoading ? (
        <div className="text-gray-400 text-lg">Carregando...</div>
      ) : (
        children
      )}
    </div>
  </div>
);

const getClassName = (className) => {
  const classMap = {
    car: "Carro",
    person: "Pessoa",
    truck: "Caminhão",
    motorcycle: "Moto",
    bus: "Ônibus",
    bicycle: "Bicicleta",
  };
  return classMap[className] || className;
};

const GrafanaDashboard = () => {
  useEffect(() => {
    const orgId = window.location.pathname.split("/")[1];
    if (orgId) {
      setOrganization(orgId);
      document.title = `${orgId} Dashboard`;
    }
  }, []);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshRate, setRefreshRate] = useState("30");
  const [timeInterval, setTimeInterval] = useState("1h");
  const [customStartTime, setCustomStartTime] = useState("");
  const [customEndTime, setCustomEndTime] = useState("");
  const [currentTime, setCurrentTime] = useState(
    new Date().toLocaleTimeString("pt-BR"),
  );
  const [updateTimestamp, setUpdateTimestamp] = useState(Date.now());
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [organization, setOrganization] = useState(null);
  // Filter states
  const [camerasData, setCamerasData] = useState([]);
  const [selectedCamera, setSelectedCamera] = useState(null);
  const [selectedZones, setSelectedZones] = useState([]);
  const [selectedClasses, setSelectedClasses] = useState([]);
  const [invalidOrg, setInvalidOrg] = useState(false);

  // Fetch filters from API
  // Wrap fetchFilters in useEffect
  useEffect(() => {
    const fetchFilters = async () => {
      try {
        setIsLoading(true);
        const orgId = window.location.pathname.split("/")[1];
        console.log("Fetching filters for org:", orgId);
    
        const response = await fetch(
          `/api/filters?organization_id=${orgId}`,
        );
        const data = await response.json();
    
        if (!data.cameras || data.cameras.length === 0) {
          console.log("No cameras found or invalid data structure");
          setInvalidOrg(true);
          setCamerasData([]); 
        } else {
          console.log("Valid cameras found:", data.cameras.length);
          setCamerasData(data.cameras);
          setInvalidOrg(false);
    
          if (data.cameras.length > 0) {
            const targetCamera = data.cameras.find(cam => cam.source_id === "67c21bcbaf777b013a674232") || data.cameras[0];
	    setSelectedCamera(targetCamera.source_id);
            setSelectedZones(targetCamera.zones);
            setSelectedClasses([]);
	    setTimeInterval("24h"); // Wide time windo
          }
        }
      } catch (error) {
        console.error("Fetch error:", error);
        setInvalidOrg(true);
        setCamerasData([]);
      } finally {
        setIsLoading(false);
      }
    };

    if (organization) {
      fetchFilters();
    }
  }, [organization]);
  
  // Time and refresh effects
  useEffect(() => {
    const timeTimer = setInterval(() => {
      setCurrentTime(new Date().toLocaleTimeString("pt-BR"));
    }, 1000);

    let refreshTimer;
    // Only set up refresh timer if refresh rate isn't "0" (no updates)
    if (refreshRate !== "0") {
      refreshTimer = setInterval(
        () => {
          if (!document.hidden) {
            setUpdateTimestamp(Date.now());
          }
        },
        parseInt(refreshRate) * 1000,
      );

      setUpdateTimestamp(Date.now());
    }

    return () => {
      clearInterval(timeTimer);
      if (refreshTimer) clearInterval(refreshTimer);
    };
  }, [refreshRate, timeInterval]);

  // Initialize custom time range with direct Recife time (UTC-3)
  useEffect(() => {
    if (timeInterval === "custom" && !customStartTime) {
      // Force Recife time directly using local timezone offset
      const now = new Date();
      
      // Get UTC hours and minutes
      const utcHours = now.getUTCHours();
      const utcMinutes = now.getUTCMinutes();
      
      // Calculate Recife time (UTC-3)
      const recifeHours = (utcHours - 3 + 24) % 24;
      
      // Format strings with correct timezone
      const pad = (num) => String(num).padStart(2, '0');
      const year = now.getUTCFullYear();
      const month = pad(now.getUTCMonth() + 1);
      const day = pad(now.getUTCDate());
      
      // Calculate day adjustment if needed
      let adjustedDay = day;
      if (utcHours < 3) {
        // If UTC is 0, 1, or 2, then Recife is on the previous day
        const yesterday = new Date(now);
        yesterday.setUTCDate(yesterday.getUTCDate() - 1);
        adjustedDay = pad(yesterday.getUTCDate());
      }
      
      // Create the time strings
      const endTime = `${year}-${month}-${adjustedDay}T${pad(recifeHours)}:${pad(utcMinutes)}`;
      
      // For start time (1 hour earlier)
      const startHours = (recifeHours - 1 + 24) % 24;
      let startDay = adjustedDay;
      
      // Handle day adjustment for start time if needed
      if (recifeHours === 0) {
        const dayBefore = new Date(now);
        dayBefore.setUTCDate(dayBefore.getUTCDate() - 1);
        startDay = pad(dayBefore.getUTCDate());
      }
      
      const startTime = `${year}-${month}-${startDay}T${pad(startHours)}:${pad(utcMinutes)}`;
      
      console.log("Direct Recife end time:", endTime);
      console.log("Direct Recife start time:", startTime);
      
      setCustomEndTime(endTime);
      setCustomStartTime(startTime);
    }
  }, [timeInterval, customStartTime]);

  // Handle camera selection
  const handleCameraChange = (cameraId) => {
    console.log("Camera change:", { cameraId, camerasData });
    const camera = camerasData.find((cam) => cam.source_id === cameraId);
    console.log("Found camera:", camera);
    if (camera) {
      setSelectedCamera(camera.source_id);
      setSelectedZones(camera.zones);
      setSelectedClasses(camera.classes);
    }
  };

  // Handle zone and class selection
  const handleZoneSelection = (zone, isSelected) => {
    setSelectedZones((prev) =>
      isSelected ? [...prev, zone] : prev.filter((z) => z !== zone),
    );
  };

  const handleClassSelection = (className, isSelected) => {
    setSelectedClasses((prev) =>
      isSelected ? [...prev, className] : prev.filter((c) => c !== className),
    );
  };

  const buildGrafanaUrl = (panel) => {
    console.log("Building URL with:", {
      organization,
      selectedCamera,
      selectedZones,
      selectedClasses,
    });

    let timeParams;
    if (timeInterval === "custom" && customStartTime && customEndTime) {
      const start = new Date(customStartTime).getTime();
      const end = new Date(customEndTime).getTime();
      timeParams = `&from=${start}&to=${end}`;
    } else {
      timeParams = `&from=now-${timeInterval}&to=now`;
    }

    // Handle no updates case (refreshRate === "0")
    const refreshParam = refreshRate === "0" ? "" : `&refresh=${refreshRate}s`;

    const url =
      `/grafana/d-solo/aebgezgows6ioa/allgraphs?` +
      `orgId=1` +
      timeParams +
      refreshParam +
      `&panelId=${panel.id}` +
      `&theme=dark` +
      `&timezone=America/Recife` +
      `&kiosk` +
      `&fullscreen` +
      `&hideControls=1` +
      `&var-organization=${organization}` +
      `&var-source_id=${selectedCamera || "$__all"}` +
      `&var-zones=${selectedZones.length ? selectedZones.join(",") : "$__all"}` +
      `&var-classes=${selectedClasses.length ? selectedClasses.join(",") : "$__all"}` +
      `&_=${updateTimestamp}`;

    console.log("Generated URL:", url);
    return url;
  };

  const panels = [
    { id: 1, title: "Detecções por categoria"},
    { id: 2, title: "Timeline"},
    { id: 3, title: "Distribuição por categoria"},
    { id: 4, title: "Timeline por câmera"},
  ];

  const currentCamera = camerasData.find(
    (cam) => cam.source_id === selectedCamera,
  );
  
  return (
    <div className="min-h-screen bg-gray-900 flex">
      {/* Organization check overlay */}
      {!organization && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="absolute inset-0 bg-gray-900 bg-opacity-50 backdrop-blur-sm"></div>
          <div className="bg-gray-800 p-8 rounded-lg shadow-xl relative">
            <h1 className="text-2xl font-bold mb-4 text-white">
              Necessário ID da organização na URL !
            </h1>
            <p className="text-lg text-white">
              Acesse esse dashboard com o ID de sua organização na URL.
            </p>
            <p className="text-gray-400 mt-2">Exemplo: /178ad</p>
          </div>
        </div>
      )}
  
      {/* Filter Sidebar - Now on the RIGHT side with sandwich icon */}
      <div
        className={`fixed inset-y-0 right-0 transform ${isSidebarOpen ? "translate-x-0" : "translate-x-full"} w-96 bg-gray-800 bg-opacity-70 backdrop-blur-md p-4 transition-transform duration-200 ease-in-out z-50`}
      >
        {/* Hamburger button outside sidebar (only shown when sidebar is closed AND organization exists) */}
        {!isSidebarOpen && organization && (
          <button
            className="absolute -left-12 top-4 bg-gray-800 p-2 rounded-l text-white w-10 h-10 flex items-center justify-center"
            onClick={() => setIsSidebarOpen(true)}
          >
            <span className="text-xl">☰</span>
          </button>
        )}
        
        {/* Close button inside sidebar */}
        {isSidebarOpen && (
          <button
            className="absolute top-4 right-4 bg-gray-700 p-2 rounded text-white w-8 h-8 flex items-center justify-center"
            onClick={() => setIsSidebarOpen(false)}
          >
            <span className="text-lg">✕</span>
          </button>
        )}
  
        <div className="h-full overflow-y-auto space-y-6">
          <FilterSection title="Câmeras" isLoading={isLoading}>
            <div>
              <p className="text-white mb-2">
                {camerasData.length} câmeras encontradas
              </p>
              <select
                value={selectedCamera || ""}
                onChange={(e) => handleCameraChange(e.target.value)}
                className="w-full p-2 bg-gray-800 text-white rounded-md border border-gray-600 focus:ring focus:ring-blue-400"
              >
                <option value="" disabled>
                  Selecione uma câmera
                </option>
                {camerasData.map((camera) => (
                  <option key={camera.source_id} value={camera.source_id}>
                    Câmera {camera.source_id}
                  </option>
                ))}
              </select>
            </div>
          </FilterSection>
  
          {currentCamera && (
            <>
              <FilterSection title="Zonas" isLoading={isLoading}>
                <div className="space-y-2">
                  {currentCamera.zones.map((zone) => (
                    <label key={zone} className="flex items-center space-x-2">
                      <input
                        type="checkbox"
                        checked={selectedZones.includes(zone)}
                        onChange={(e) =>
                          handleZoneSelection(zone, e.target.checked)
                        }
                        className="form-checkbox text-blue-400"
                      />
                      <span className="text-white text-lg">{zone}</span>
                    </label>
                  ))}
                </div>
              </FilterSection>
  
              <FilterSection title="Classes" isLoading={isLoading}>
                <div className="grid grid-cols-2 gap-2">
                  {currentCamera.classes.map((className) => (
                    <label
                      key={className}
                      className="flex items-center space-x-2"
                    >
                      <input
                        type="checkbox"
                        checked={selectedClasses.includes(className)}
                        onChange={(e) =>
                          handleClassSelection(className, e.target.checked)
                        }
                        className="form-checkbox text-blue-400"
                      />
                      <span className="text-white text-lg">
                        {getClassName(className)}
                      </span>
                    </label>
                  ))}
                </div>
              </FilterSection>
            </>
          )}
        </div>
      </div>
  
      {/* Main Content */}
      <div className="flex-1">
        <nav className="bg-gray-800 border-b border-gray-700 px-6 py-4">
          <div className="max-w-8xl mx-auto flex justify-between items-center">
            {/* Rearranged layout - Time controls first, then org info */}
            <div className="flex items-center space-x-6">
              <div className="flex items-center space-x-2">
                <select
                  value={timeInterval}
                  onChange={(e) => setTimeInterval(e.target.value)}
                  className="bg-gray-700 text-white px-3 py-2 rounded-md border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                  <option value="15m">Últimos 15 min</option>
                  <option value="30m">Últimos 30 min</option>
                  <option value="1h">Última hora</option>
                  <option value="3h">Últimas 3 horas</option>
                  <option value="6h">Últimas 6 horas</option>
                  <option value="12h">Últimas 12 horas</option>
                  <option value="24h">Últimas 24 horas</option>
                  <option value="custom">Intervalo Personalizado</option>
                </select>
              </div>

              {timeInterval === "custom" && (
                <div className="flex items-center space-x-2">
                  <input
                    type="datetime-local"
                    value={customStartTime}
                    onChange={(e) => setCustomStartTime(e.target.value)}
                    className="bg-gray-700 text-white px-3 py-2 rounded-md border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                  <span className="text-white">até</span>
                  <input
                    type="datetime-local"
                    value={customEndTime}
                    onChange={(e) => setCustomEndTime(e.target.value)}
                    className="bg-gray-700 text-white px-3 py-2 rounded-md border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  />
                </div>
              )}

              <div className="flex items-center space-x-2">
                <select
                  value={refreshRate}
                  onChange={(e) => setRefreshRate(e.target.value)}
                  className="bg-gray-700 text-white px-3 py-2 rounded-md border border-gray-600 focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                >
                  <option value="0">Sem atualizações</option>
                  <option value="5">Atualizar: 5s</option>
                  <option value="10">Atualizar: 10s</option>
                  <option value="30">Atualizar: 30s</option>
                  <option value="60">Atualizar: 1m</option>
                </select>
              </div>
            </div>

            {/* Organization and logo with increased padding - positions swapped */}
            <div className="flex items-center space-x-3 pr-12">
              {organization && (
                <span className="text-white bg-blue-600 px-5 py-2 rounded-md mr-4">
                  {organization}
                </span>
              )}
              <img src="plenus.png" alt="Logo" className="w-38 h-8" />
            </div>
          </div>
        </nav>

        <main className="p-8">
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            {panels.map((panel) => (
              <div
                key={panel.id}
                className="bg-gray-800 rounded-lg border border-gray-700 p-6"
              >
                <div className="flex items-center space-x-2 mb-6">
                  <span className="text-blue-400 text-2xl">{panel.icon}</span>
                  <h2 className="text-xl text-white">{panel.title}</h2>
                </div>
                <div className="h-[600px]">
                  <PreloadedIframe
                    title={panel.title}
                    src={buildGrafanaUrl(panel)}
                    height="100%"
                  />
                </div>
              </div>
            ))}
          </div>
        </main>
      </div>
    </div>
  );
};

export default GrafanaDashboard;
